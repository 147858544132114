import styled from '@emotion/styled';
import { Link } from 'gatsby';
import bgImg from '../../static/images/404.png';
import { Layout } from '../components/Layout';
import { colors } from '../components/Theme/Colors';

const NotFoundPageContent = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('${bgImg}');
  background-repeat: no-repeat;
  background-size: cover;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: ${colors.backgroundDark};
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.6rem;

    h4 {
      font-weight: 500;
      font-size: 4.8rem;
      line-height: 5.5rem;
      text-align: center;

      color: ${colors.notFoundTitle};
      text-transform: uppercase;
      margin-bottom: 1.6rem;
    }
    h5 {
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.04em;
      color: ${colors.notFoundDesc};
      margin-bottom: 4rem;
      text-align: center;
    }
    p {
      font-size: 1.2rem;
      line-height: 126%;
      letter-spacing: 0.04em;
      color: ${colors.notFoundDesc};
      text-transform: uppercase;
      text-align: center;

      a {
        color: ${colors.organge};
        text-decoration: none;
      }
    }
  }
`;

const NotFoundPage = ({ location }) => (
  <Layout hasFooter={false} location={location} lightHeader>
    <NotFoundPageContent>
      <Wrapper>
        <div className="overlay">
          <h4>Page not found</h4>
          <h5>
            The link you clicked maybe broken or the page may have removed
          </h5>
          <p>
            Visit our{' '}
            <Link className="hoverable" to="/">
              Homepage
            </Link>{' '}
            or{' '}
            <Link className="hoverable" to="/">
              Contact
            </Link>{' '}
            us about this problem
          </p>
        </div>
      </Wrapper>
    </NotFoundPageContent>
  </Layout>
);

export default NotFoundPage;
